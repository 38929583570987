import React, { useState, useEffect } from "react";
import "./BurgerMenu.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import logedin from "../../../assets/images/header/logedin.webp";
import isAuth from "../../../Utils/isAuth";
import { getmyinfo } from "../../../Redux/User/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../Redux/Auth/AuthActions";

function BurgerMenu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [islogedin, setislogedin] = useState(true);
  const [openJobs, setOpenJobs] = useState(false);
  const [openOurImpact, setOpenOurImpact] = useState(false);
  const [openPlatforms, setOpenPlatforms] = useState(false);
  const { userinfo } = useSelector((state) => state.User);
  useEffect(() => {
    setislogedin(isAuth(dispatch));
  }, [islogedin]);
  useEffect(() => {
    if (islogedin) {
      dispatch(getmyinfo());
    }
  }, [islogedin]);
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <div className="Main_Burger_div">
      <div className="Burger_container">
        <div className="close_burger" onClick={() => props.setIsShown(false)}>
          <AiOutlineCloseCircle size={30} className="close_burger_icon" />
        </div>
        {islogedin && (
          <div
            className="Burger_logedin_container"
            onClick={() => navigate("/profile")}
          >
            <div className="profile_image_container">
              <img
                src={process.env.REACT_APP_API + "/user/" + userinfo.img_url}
                alt="profile"
                className="profile_image"
              />
            </div>
            <div className="Burger_logedin_text"> {userinfo.full_name}</div>
          </div>
        )}

        <div className="Burger_nav_container">
          <div className="Burger_nav_item" onClick={() => navigate("/aboutus")}>
            <div className="Burger_nav_item_text">About us</div>
            <div className="divider" />
          </div>
          <div className="Burger_nav_item" onClick={() => setOpenJobs(!openJobs)}>
            <div className="Burger_nav_item_text">Jobs</div>
            <div className="divider" />
          </div>
          {openJobs &&
            <div className="Burger_nav_item" onClick={() => navigate("/joblisting")}>
              <div className="Burger_nav_item_text green-color">Find jobs</div>
              <div className="divider" />
            </div>
          }
          {openJobs &&
            <div className="Burger_nav_item" onClick={() => navigate("/cvbuilder")}>
              <div className="Burger_nav_item_text green-color">Create your CV</div>
              <div className="divider" />
            </div>
          }

          <div className="Burger_nav_item" onClick={() => navigate("/companies")}>
            <div className="Burger_nav_item_text">Companies</div>
            <div className="divider" />
          </div>



          <div className="Burger_nav_item" onClick={() => setOpenOurImpact(!openOurImpact)}>
            <div className="Burger_nav_item_text">Our impact</div>
            <div className="divider" />
          </div>
          {openOurImpact &&
            <div className="Burger_nav_item" onClick={() => navigate("/cvbuilder")}>
              <div className="Burger_nav_item_text green-color">Sucess Stories</div>
              <div className="divider" />
            </div>
          }
          {openOurImpact &&
            <div className="Burger_nav_item" onClick={() => navigate("/qna")}>
              <div className="Burger_nav_item_text green-color">Q&A</div>
              <div className="divider" />
            </div>
          }



          <div className="Burger_nav_item" onClick={() => navigate("/contactus")}>
            <div className="Burger_nav_item_text">Get involved</div>
            <div className="divider" />
          </div>



          <div className="Burger_nav_item" onClick={() => setOpenPlatforms(!openPlatforms)}>
            <div className="Burger_nav_item_text">Platforms</div>
            <div className="divider" />
          </div>


          {openPlatforms &&
            <div className="Burger_nav_item" onClick={() => window.location.href = "https://mehnati.org/"}>
              <div className="Burger_nav_item_text green-color">Mehnati</div>
              <div className="divider" />
            </div>
          }
          {openPlatforms &&
            <div className="Burger_nav_item" onClick={() => window.location.href = "https://lms.mehnati.org/"}>
              <div className="Burger_nav_item_text green-color">Lms</div>
              <div className="divider" />
            </div>
          }
          {openPlatforms &&
            <div className="Burger_nav_item" onClick={() => window.location.href = "https://schools.mehnati.org/"}>
              <div className="Burger_nav_item_text green-color">Schools</div>
              <div className="divider" />
            </div>
          }

          {islogedin ? (
            <div className="Burger_nav_item" onClick={handleLogout}>
              <div className="Burger_nav_item_text">Log out</div>
              <div className="divider" />
            </div>
          ) : (
            <div className="Burger_nav_item" onClick={() => navigate("/login")}>
              <div className="Burger_nav_item_text">Login</div>
              <div className="divider" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BurgerMenu;
